@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    /* Hide scrollbar for Edge, IE, and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none;
}

.hidden {
    overflow: hidden;
}

/* Panel Glass*/
.bg-glass {
    padding: 4px;
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: "blur(6px)";
    border-color: rgb(255, 255, 255, 0);
    box-shadow:
        0px 0px 11px 1px rgba(228, 255, 254, 0.55),
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;
}

/* 3D Scene */
.mapboxgl-control-container {
    display: none !important;
}
